$(() => {
  // 加载本地搜索历史
  loadSearchHistories()
  // 搜索默认 focus
  $(".sfm-mobile-search #key").focus();

  // 顶部 navbar 以及移动端 搜索文档
  $(document).on("click", ".sfm-hybrid-search .submit-search-docs-btn", function () {
    $(this).parents(".sfm-hybrid-search").find("form").prop("action", "/doc/search");
  })

  // 顶部 navbar 以及移动端 搜索软件
  $(document).on("click", ".sfm-hybrid-search .submit-search-apps-btn", function () {
    $(this).parents(".sfm-hybrid-search").find("form").prop("action", "/apps/search");
  })

  // 顶部 navbar 搜索软件
  $(document).on("click", ".sfm-mobile-global-search-form .search-input", function (e) {
    e.preventDefault();
    e.stopPropagation();
    window.location.href = "/search";
  })

  // 加载本地搜索历史
  function loadSearchHistories() {
    const historyItemsEl = $(".sfm-mobile-search .history-items");
    if (historyItemsEl.length === 0) {
      historyItemsEl.html('');
      return;
    }

    const histories = JSON.parse(localStorage.getItem("search_histories"));
    if (!Array.isArray(histories)) {
      historyItemsEl.html('');
      return;
    }

    let html = '';
    histories.forEach((history) => {
      html = `<div class="history-item" data-key="${history[0]}" data-type="${history[1]}">${history[0]}</div>` + html;
    })
    historyItemsEl.append(html);
  }

  // 搜索搜索历史到本地
  function saveSearchHistory(key, type) {
    if (!key) {
      return
    }

    let histories = JSON.parse(localStorage.getItem("search_histories")) || [];
    // 删除已经存在的同样搜索记录
    histories = histories.filter(function (history) {
      return history[0] !== key || history[1] !== type;
    })

    histories.push([key, type]);
    localStorage.setItem("search_histories", JSON.stringify(histories));
  }

  // 移动端搜索保存搜索历史到本地
  $(document).on("click", ".sfm-mobile-search .save-search-history", function () {
    saveSearchHistory($(this).parents(".sfm-mobile-search").find("#key").val(), $(this).data("type"));
  })

  // 移动端清空本地搜索记录
  $(document).on("click", ".sfm-mobile-search .history-remove-all", function () {
    localStorage.removeItem("search_histories");
    loadSearchHistories();
  })

  // 移动端历史记录点击搜索
  $(document).on("click", ".sfm-mobile-search .history-item", function () {
    const key = $(this).data("key");
    const type = $(this).data("type");
    $(this).parents(".sfm-mobile-search").find("#key").val(key);
    $(this).parents(".sfm-mobile-search").find(`.submit-search-btn[data-type="${type}"]`).click();
  })

})