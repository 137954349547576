$(() => {
    $(document).on('click', '.sfm-user-upload-record-list .show-image', function (e) {
        previewImg($(this).attr("value"))
    })

    $(document).on('click', '.sfm-show-user-upload-record .show-image', function (e) {
        previewImg($(this).attr("value"))
    })

    $('#previewImageModal').on('hidden.bs.modal', function (e) {
        // 关闭图片预览模态框后 重置src
        $('#poster_img').attr("src", "");
    })

    // 添加文件 编辑页面
    $(document).on('change', '#edit-user-upload-record #upload-file-edit-input', function (e) {
        addFile(e)
    })

    // 删除文件 编辑页面
    $(document).on('click', '#edit-user-upload-record .delete-file-button', function (e) {
        document.getElementById('upload-file-edit-input').value = ""
        deleteFile()
    })

    // 点击上传文件 编辑页面
    $(document).on('click', '#edit-user-upload-record .upload-file-button', function (e) {
        document.getElementById('upload-file-edit-input').click()
    })

    // 添加文件 新增页面
    $(document).on('change', '#new-user-upload-record #upload-file-new-input', function (e) {
        addFile(e)
    })

    // 删除文件 新增页面
    $(document).on('click', '#new-user-upload-record .delete-file-button', function (e) {
        document.getElementById('upload-file-new-input').value = ""
        deleteFile()
    })

    // 点击上传文件 新增页面
    $(document).on('click', '#new-user-upload-record .upload-file-button', function (e) {
        document.getElementById('upload-file-new-input').click()
    })

    // 添加封面 编辑页面
    $(document).on('change', '#edit-user-upload-record #upload-poster-edit-input', function (e) {
        addPoster(e)
    })

    // 删除封面 编辑页面
    $(document).on('click', '#edit-user-upload-record .delete-poster-button', function (e) {
        document.getElementById('upload-poster-edit-input').value = ""
        deletePoster()
    })

    // 点击上传封面 编辑页面
    $(document).on('click', '#edit-user-upload-record .upload-poster-button', function (e) {
        document.getElementById('upload-poster-edit-input').click()
    })

    // 添加封面 新增页面
    $(document).on('change', '#new-user-upload-record #upload-poster-new-input', function (e) {
        addPoster(e)
    })

    // 删除封面 新增页面
    $(document).on('click', '#new-user-upload-record .delete-poster-button', function (e) {
        document.getElementById('upload-poster-new-input').value = ""
        deletePoster()
    })

    // 点击上传封面 新增页面
    $(document).on('click', '#new-user-upload-record .upload-poster-button', function (e) {
        document.getElementById('upload-poster-new-input').click()
    })

    $(document).on('submit', '#new-upload-record-form', function (event) {
        if (event.preventDefault) {
            event.preventDefault()
        } else {
            window.event.returnValue == false
        }

        // 验证文件格式
        if (!checkFileType(document.getElementById('upload-file-new-input'))) {
            return false
        }

        if (!checkPosterType(document.getElementById('upload-poster-new-input'))) {
            return false
        }

        const that = $(this)
        const formData = new FormData(that[0])
        const submitValue = that.find("input[type='submit']").val()
        console.log(submitValue)
        that.find("input[type='submit']").val("上传中...")
        that.find("input[type='submit']").prop('disabled', true)
        $.ajax({
            type: "post",
            url: $(this).attr("action"),
            dataType: "json",
            data: formData,
            contentType: false,
            processData: false,
            success: function (res) {
                userUploadRecordAjaxSuccess(res, that, submitValue)
            }
        })
    })

    $(document).on('submit', '#edit-upload-record-form', function (event) {
        if (event.preventDefault) {
            event.preventDefault()
        } else {
            window.event.returnValue == false
        }

        // 验证文件格式
        if (!checkFileType(document.getElementById('upload-file-edit-input'))) {
            return false
        }
        if (!checkPosterType(document.getElementById('upload-poster-edit-input'))) {
            return false
        }

        let that = $(this)
        const formData = new FormData(that[0])
        const submitValue = that.find("input[type='submit']").val()
        that.find("input[type='submit']").val("上传中...")
        that.find("input[type='submit']").prop('disabled', true)
        $.ajax({
            type: "post",
            url: $(this).attr("action"),
            dataType: "json",
            data: formData,
            contentType: false,
            processData: false,
            success: function (res) {
                userUploadRecordAjaxSuccess(res, that, submitValue)
            }
        })
    })

})

function userUploadRecordAjaxSuccess(res, that, submitValue) {
    that.find("input[type='submit']").val(submitValue)
    that.find("input[type='submit']").prop('disabled', false)
    if (res.code == "0") {
        window.location.href = "/user_upload_records"
    } else {
        alertNotice(res.message)
    }
}

function addFile(e) {
    $('.upload-file-file-name').text(e.target.files[0].name)
    $('.upload-file-file-name').css("display", "block")
    $('.upload-file-button').css("display", "none")
    $('.delete-file-button').css("display", "block")
    $('.user-upload-edit-file-name').val(e.target.files[0].name)
    // $('#upload-file-edit-file-size').text(e.target.files[0].size)
    // $('#upload-file-edit-file-type').text(e.target.files[0].type)
}

function deleteFile() {
    $('.delete-file-button').css("display", "none")
    $('.upload-file-file-name').css("display", "none")
    $('.upload-file-button').css("display", "block")
    $('.user-upload-edit-file-name').val('')
    // $('#upload-file-edit-file-size').text('')
    // $('#upload-file-edit-file-type').text('')
}

function addPoster(e) {
    $('.upload-poster-file-name').text(e.target.files[0].name)
    $('.upload-poster-file-name').css("display", "block")
    $('.upload-poster-button').css("display", "none")
    $('.delete-poster-button').css("display", "block")
    $('.user-upload-edit-poster-name').val(e.target.files[0].name)
}

function deletePoster() {
    $('.delete-poster-button').css("display", "none")
    $('.upload-poster-file-name').css("display", "none")
    $('.upload-poster-button').css("display", "block")
    $('.user-upload-edit-poster-name').val('')
}

function previewImg(value) {
    // 设置图片地址
    // 打开模态框
    showModal(`
            <div class="sfm_modal" id="preview_document_poster" style="display: block;">
                <form>
                    <div class="logo">
                        <img class="sfm_close" src="/baseline-close-px.png">
                    </div>
                    <img id="poster_img" src="" style="width: 100%">
                </form>
            </div>
        `, '#preview_document_poster')
    $('#poster_img').attr("src", value);
}

// 处理编辑页面的file组件问题
window.fetchFileData = function (posterFileName) {
    $('.upload-poster-file-name').text(posterFileName)
    $('.upload-poster-file-name').css("display", "block")
    $('.upload-poster-button').css("display", "none")
    $('.delete-poster-button').css("display", "block")
}

function checkFileType(element) {
    if (element.files.length > 0) {
        const file = element.files[0]
        const fileName = file.name
        const correctTypes = ['doc', 'docx', 'ppt', 'pptx', 'pdf', 'xls', 'xlsx', 'txt']
        const fileType = fileName.substring(fileName.lastIndexOf('.') + 1)
        if (!correctTypes.includes(fileType)) {
            element.value = ""
            deleteFile()
            alertError("文件格式错误")
            return false
        } else if (file.size > 20 * 1024 * 1024 * 1024) {
            element.value = ""
            deletePoster()
            alertError("文件大小不能超过20GB")
            return false
        }
    }

    return true
}

function checkPosterType(element) {
    if (element.files.length > 0) {
        const file = element.files[0]
        const fileName = file.name
        const correctTypes = ['png', 'jpg', 'jpeg']
        const fileType = fileName.substring(fileName.lastIndexOf('.') + 1)
        if (!correctTypes.includes(fileType)) {
            element.value = ""
            deletePoster()
            alertError("文件格式错误")
            return false
        } else if (file.size > 10 * 1024 * 1024) {
            element.value = ""
            deletePoster()
            alertError("文件大小不能超过10M")
            return false
        }
    }
    return true
}
