$(() => {
  // 如果没有点击过关闭 上传送积分推广弹框 则显示弹框
  if (!localStorage.getItem("close_upload_promotion_popup")) {
    $(".sfm-upload-promotion .popup").removeClass("hide");
  }

  // 上传送积分推广图标点击
  $(document).on("click", ".sfm-upload-promotion .tool-icon", function () {
    const popup = $(this).parents(".sfm-upload-promotion").find(".popup");
    if (popup.hasClass("hide")) {
      popup.removeClass("hide");
    } else {
      popup.addClass("hide");
    }
  })

  // 上传送积分推广 关闭弹框
  $(document).on("click", ".sfm-upload-promotion .close-popup", function () {
    $(this).parents(".popup").addClass("hide");
    localStorage.setItem("close_upload_promotion_popup", "1");
  })
})