$(() => {
    // 添加封面 新增页面
    $(document).on('change', '#new-report-record #upload-photo1-new-input', function (e) {
        addReportPhoto(e, 1)
    })

    // 删除封面 新增页面
    $(document).on('click', '#new-report-record .delete-photo1-button', function (e) {
        document.getElementById('upload-photo1-new-input').value = ""
        deleteReportPhoto(1)
    })

    // 点击上传封面 新增页面
    $(document).on('click', '#new-report-record .upload-photo1-button', function (e) {
        document.getElementById('upload-photo1-new-input').click()
    })

    // 添加封面 新增页面
    $(document).on('change', '#new-report-record #upload-photo2-new-input', function (e) {
        addReportPhoto(e, 2)
    })

    // 删除封面 新增页面
    $(document).on('click', '#new-report-record .delete-photo2-button', function (e) {
        document.getElementById('upload-photo2-new-input').value = ""
        deleteReportPhoto(2)
    })

    // 点击上传封面 新增页面
    $(document).on('click', '#new-report-record .upload-photo2-button', function (e) {
        document.getElementById('upload-photo2-new-input').click()
    })

    $(document).on('click', '.sfm-show-report-record .show-image', function (e) {
        // 设置图片地址
        // 打开模态框
        showModal(`
            <div class="sfm_modal" id="preview_document_poster" style="display: block;">
                <form>
                    <div class="logo">
                        <img class="sfm_close" src="/baseline-close-px.png">
                    </div>
                    <img id="poster_img" src="" style="width: 100%">
                </form>
            </div>
        `, '#preview_document_poster')
        $('#poster_img').attr("src", $(this).attr("value"));
    })

    $(document).on('submit', '#new-report-record-form', function (event) {
        // 验证必填项
        if (!$(this).find("#report-record-content-input").val()) {
            alertError("举报说明不能为空！")
            return false
        }
    })
})

function addReportPhoto(e, index) {
    $('.upload-photo'+index+'-file-name').text(e.target.files[0].name)
    $('.upload-photo'+index+'-file-name').css("display", "block")
    $('.upload-photo'+index+'-button').css("display", "none")
    $('.delete-photo'+index+'-button').css("display", "block")
}

function deleteReportPhoto(index) {
    $('.delete-photo'+index+'-button').css("display", "none")
    $('.upload-photo'+index+'-file-name').css("display", "none")
    $('.upload-photo'+index+'-button').css("display", "block")
}