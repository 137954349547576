$(() => {
    $(document).on('click', '.sfm-collection-list .collection-check-box', function (e) {
        let value = document.getElementById('collection-ids').value.trim().split(',').filter(item => item != '')
        const id = $(this).attr("id")
        if (this.checked) {
            value.push(id)
        } else {
            value.splice(value.findIndex(item => item === id), 1)
        }
        document.getElementById('collection-ids').value = value
    })

    // 点击mask-checkbox-box 遮蔽层 同步点击checkbox
    $(document).on('click', '.sfm-collection-list .mask-checkbox-box', function (e) {
        $(this).children('.collection-check-box')[0].click()
    })

    // 编辑
    $(document).on('click', '.sfm-collection-list .edit-collection', function (e) {
        $(this).css("display", "none")
        // 将文档的mask-checkbox-box div 设置宽高 展开遮蔽层 遮挡文档组件 防止误触
        $(this).parents('.sfm-collection-list').find('.mask-checkbox-box').css({"width": "100%", "height": "100%"})
        $(this).parents('.sfm-collection-list').find('.action-button').css("display", "flex")
        $(this).parents('.sfm-collection-list').find('.collection-check-box').css("display", "block")
    })

    // 取消编辑
    $(document).on('click', '.sfm-collection-list .cancel-collection', function (e) {
        // 将文档的mask-checkbox-box div 设置宽高为0 关闭遮蔽层
        $(this).parents('.sfm-collection-list').find('.mask-checkbox-box').css({"width": "0", "height": "0"})
        $(this).parents('.sfm-collection-list').find('.action-button').css("display", "none")
        $(this).parents('.sfm-collection-list').find('.edit-collection').css("display", "block")
        $(this).parents('.sfm-collection-list').find('.collection-check-box').css("display", "none")
    })

    // 全选
    $(document).on('change', '.sfm-collection-list .check-all-box', function (e) {
        const checkbox = $(this).parents('.sfm-collection-list').find('.collection-check-box')
        const isWillChecked = this.checked
        let value = []
        for (let i = 0; i < checkbox.length; i++) {
            checkbox[i].checked = isWillChecked
            value.push(checkbox[i].value)
        }
        if (!isWillChecked) {
            value = []
        }
        document.getElementById('collection-ids').value = value
    })

    // 删除
    $(document).on('click', '.sfm-collection-list .delete-collection', function (e) {
        const form = $(this).parents(".sfm-collection-list").find(".edit-collection-form")
        // 提交表单
        form.submit();
    })
})