/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
import 'bootstrap/dist/js/bootstrap'
import ClipboardJS from 'clipboard/dist/clipboard'

require("@rails/ujs").start()
import $ from 'jquery';

global.$ = $;
global.ClipboardJS = ClipboardJS;

function importAll(r) {
    r.keys().forEach(r);
}

importAll(require.context('pages', true, /\.js$/));

$(() => {
    var timer = null
    timer = setTimeout(function () {
        $('.sfm-flash.auto-remove').remove()
    }, 2000)
    //移动端
    if (window.screen.width <= 750) {
        $(document).on('submit', '.sfm_users form', function (event) {
            var that = $(this)
            if (event.preventDefault) {
                event.preventDefault();
            } else {
                window.event.returnValue == false;
            }
            clearTimeout(timer)
            if ($(this).attr("action") == "/users") {
                if (!$(this).find("#user_email").val()) {
                    $("body").append('<div class="sfm-flash"><div class="sfm-flash-msg"><span class="notice">邮箱不能为空！</span></div></div>')
                    timer = setTimeout(function () {
                        $('.sfm-flash').remove()
                    }, 2000)
                    return
                }
                if (!isEmailValid($(this).find("#user_email").val())) {
                    $("body").append('<div class="sfm-flash"><div class="sfm-flash-msg"><span class="notice">邮箱格式不正确！</span></div></div>')
                    timer = setTimeout(function () {
                        $('.sfm-flash').remove()
                    }, 2000)
                    return
                }
                if (!$(this).find("#user_password").val()) {
                    $("body").append('<div class="sfm-flash"><div class="sfm-flash-msg"><span class="notice">密码不能为空！</span></div></div>')
                    timer = setTimeout(function () {
                        $('.sfm-flash').remove()
                    }, 2000)
                    return
                }
                if (!$(this).find("#user_password_confirmation").val()) {
                    $("body").append('<div class="sfm-flash"><div class="sfm-flash-msg"><span class="notice">确认密码不能为空！</span></div></div>')
                    timer = setTimeout(function () {
                        $('.sfm-flash').remove()
                    }, 2000)
                    return
                }
                if (!$('.sfm_captcha input').val()) {
                    $("body").append('<div class="sfm-flash"><div class="sfm-flash-msg"><span class="notice">验证码不能为空！</span></div></div>')
                    timer = setTimeout(function () {
                        $('.sfm-flash').remove()
                    }, 2000)
                    return
                }
                if (!$(this).find("input[type='checkbox']").prop("checked")) {
                    $("body").append('<div class="sfm-flash"><div class="sfm-flash-msg"><span class="notice">请勾选平台服务协议</span></div></div>')
                    timer = setTimeout(function () {
                        $('.sfm-flash').remove()
                    }, 2000)
                    return
                }
            }

            if ($(this).attr("action").indexOf("/users/sign_in") >= 0) {
                if (!$(this).find("#user_login").val()) {
                    $("body").append('<div class="sfm-flash"><div class="sfm-flash-msg"><span class="notice">用户名不能为空！</span></div></div>')
                    timer = setTimeout(function () {
                        $('.sfm-flash').remove()
                    }, 2000)
                    return
                }
                console.log($(this).find("#user_password").val(), 888)
                if (!$(this).find("#user_password").val()) {
                    $("body").append('<div class="sfm-flash"><div class="sfm-flash-msg"><span class="notice">密码不能为空！</span></div></div>')
                    timer = setTimeout(function () {
                        $('.sfm-flash').remove()
                    }, 2000)
                    return
                }
            }

            that.find("input[type='submit']").prop('disabled', true)
            $.ajax({
                type: "post",
                url: $(this).attr("action"),
                dataType: "json",
                data: $(this).serialize(),
                success: function (res) {
                    that.find("input[type='submit']").prop('disabled', false)
                    if (res.code == "0") {
                        if (window.location.href.indexOf("/users/sign_in") >= 0) {
                            window.location.href = "/apps?type=recommend"
                        } else if (window.location.href.indexOf("/users/test2/nick_name_setting") >= 0) {
                            window.location.href = "/user_info"
                        } else if (window.location.href.indexOf("/users/test2/password_setting") >= 0 || window.location.href.indexOf("/users") >= 0) {
                            window.location.href = "/users/sign_in/?type=account"
                        }
                    } else {
                        $(".rucaptcha-image").click()
                        $("body").append('<div class="sfm-flash"><div class="sfm-flash-msg"><span class="notice">' + res.message + '</span></div></div>')
                        timer = setTimeout(function () {
                            $('.sfm-flash').remove()
                        }, 2000)
                    }
                }
            });
        });

        //邮箱验证码提交
        $(document).on('click', '.email_submit_mbtn', function (e) {
            if (e.preventDefault) {
                e.preventDefault();
            } else {
                window.event.returnValue == false;
            }
            clearTimeout(timer)
            if (!$("#captcha").val() || !$("#email").val()) {
                return
            }
            var that = $(this)
            that.prop('disabled', true)
            $.ajax({
                type: "post",
                url: "/users/" + $("input[name='id']").val() + "/save_email",
                dataType: "json",
                data: $(this).parents("form").serialize(),
                success: function (res) {
                    that.prop('disabled', false)
                    if (res.code == "00001") {
                        resetEmailCaptchaBtn();
                        $("body").append('<div class="sfm-flash"><div class="sfm-flash-msg"><span class="notice">' + res.message + '</span></div></div>')
                        timer = setTimeout(function () {
                            $('.sfm-flash').remove()
                        }, 2000)
                    } else {
                        window.location.href = "/user_info"
                    }
                }
            });
        });
    }

    var client = new OSS.Wrapper({
        accessKeyId: 'LTAIbW3TXatTSBZH', accessKeySecret: 'nHnSsGlWx8CKr92sQ0eJcuyLbmtxUW', region: 'oss-cn-qingdao', //
        bucket: 'message-images',//
        secure: true,
    });

    //分类，更多，隐藏panel a链接跟url一致时，点击显示
    $('.software_tab > div').each(function () {
        $(this).find('.panel').find('a').each(function () {
            console.log((location.pathname + location.search) == $(this).attr('href'))
            if ((location.pathname + location.search) == $(this).attr('href')) {
                $(this).parents('.panel').css('display', 'inline-block')
            }
        })
    });

    $(document).on('click', '.sfm_btn', function (event) {
        $(".sfm_modal").css("display", "none")
        $(".sfm_fade").css("display", "block")
        if ($(this).data("toggle") == "sfm_tip_member_less_points") {
            $("#sfm_tip_member_less_points").find('.points').text($(this).attr('point'))
        }
        if ($(this).data("toggle") == "sfm_tip_member_points") {
            $("#sfm_tip_member_points").find('.points').text($(this).attr('point'))
            $('.left-points').text($(this).attr('balance') - $(this).attr('point'))
            $('.quickDownlod').attr('href', $(this).attr('url'))
        }
        if ($(this).data("toggle") == "sfm_free_download") {
            if ($(this).attr('speed') == 'common') {
                $('.low_speed').css('display', 'block')
                $('.high_speed').css('display', 'none')
            } else {
                $('.low_speed').css('display', 'none')
                $('.high_speed').css('display', 'block')
            }
            $('#sfm_free_download').find('.sfm_tip_ok').attr('href', $(this).attr('url'))
        }
        $("#" + $(this).data("toggle")).css("display", "block")
    });

    $(document).on('click', '.vip-user', function (e) {
        if ($(this).next(".vip-dialog").attr("style") == "") {
            $(this).next(".vip-dialog").hide();
        } else {
            $(this).next(".vip-dialog").show();
        }
    })

    //提示信息模态框关闭sfm_tip_ok
    $(document).on('click', '.sfm_tip_ok', function (event) {
        $('.sfm_modal, .sfm_fade').css('display', 'none')
    });

    $(document).on('click', '.sfm_fade, .sfm_cancel, .sfm_close, .handleCancel, .quickDownlod', function (event) {
        $('.sfm_modal, .sfm_fade').css('display', 'none')
        if ($('#sfm_bind_email form').length > 0) {
            $('#sfm_bind_email form')[0].reset()
        }
        if ($('#sfm_bind_phone form').length > 0) {
            $('#sfm_bind_phone form')[0].reset()
        }
        resetEmailCaptchaBtn()
        resetPhoneCaptchaBtn()
    });

    if (window.screen.width > 750) {
        $(document).on('submit', '.sfm_form', function (event) {
            var data = ''
            if (event.preventDefault) {
                event.preventDefault();
            } else {
                window.event.returnValue == false;
            }
            clearTimeout(timer)
            var that = $(this)
            console.log(that.find('div.tab_content').attr('index'), 9999)
            if (that.attr('index') == 'account') {
                if (!$("#user_login").val()) {
                    $("body").append('<div class="sfm-flash"><div class="sfm-flash-msg"><span class="notice">用户名不能为空</span></div></div>')
                    timer = setTimeout(function () {
                        $('.sfm-flash').remove()
                    }, 2000)
                    return
                }
                if (!$("#user_password").val()) {
                    $("body").append('<div class="sfm-flash"><div class="sfm-flash-msg"><span class="notice">密码不能为空</span></div></div>')
                    timer = setTimeout(function () {
                        $('.sfm-flash').remove()
                    }, 2000)
                    return
                }
                data = $.param({
                    'authenticity_token': $("meta[name='csrf-token']").attr("content"),
                    'user[login]': $("#user_login").val(),
                    'user[password]': $("#user_password").val(),
                    'user[remember_me]': $("#user_remember_me").val(),
                    "login_path": "account_login"
                })
            } else if (that.attr('index') == 'sms') {
                if (!$("#user_phone_number").val()) {
                    $("body").append('<div class="sfm-flash"><div class="sfm-flash-msg"><span class="notice">手机号不能为空</span></div></div>')
                    timer = setTimeout(function () {
                        $('.sfm-flash').remove()
                    }, 2000)
                    return
                }
                if (!$("#user_code").val()) {
                    $("body").append('<div class="sfm-flash"><div class="sfm-flash-msg"><span class="notice">验证码不能为空</span></div></div>')
                    timer = setTimeout(function () {
                        $('.sfm-flash').remove()
                    }, 2000)
                    return
                }
                data = $.param({
                    'authenticity_token': $("meta[name='csrf-token']").attr("content"),
                    'user[phone_number]': $("#user_phone_number").val(),
                    'user[code]': $("#user_code").val(),
                    "login_path": "sms_login"
                })
            } else {
                if ($(this).find("#user_email").val() && !isEmailValid($(this).find("#user_email").val())) {
                    $("body").append('<div class="sfm-flash"><div class="sfm-flash-msg"><span class="notice">邮箱格式不正确！</span></div></div>')
                    timer = setTimeout(function () {
                        $('.sfm-flash').remove()
                    }, 2000)
                    return
                }

                data = $.param({'authenticity_token': $("meta[name='csrf-token']").attr("content")}) + '&' + $(this).serialize()
            }

            that.find("input[type='submit']").prop('disabled', true)
            $.ajax({
                type: "post", url: $(this).attr("url"), dataType: "json", data: data, success: function (res) {
                    that.find("input[type='submit']").prop('disabled', false)
                    if (res.code == "0") {
                        window.location.reload()
                    } else {
                        $(".rucaptcha-image").click()
                        $("body").append('<div class="sfm-flash"><div class="sfm-flash-msg"><span class="notice">' + res.message + '</span></div></div>')
                        timer = setTimeout(function () {
                            $('.sfm-flash').remove()
                        }, 2000)
                    }
                }
            });
        });

        $('.sfm_form .tab span').click(function () {
            $(".sfm_form .tab span").removeClass('active')
            $(this).addClass('active')
            if ($(this).attr('index') == '0') {
                $('.account').css('display', 'block')
                $('.sms').css('display', 'none')
                $("#sfm_sign_in .sfm_form").attr('index', 'account')
            } else {
                $('.account').css('display', 'none')
                $('.sms').css('display', 'block').attr('index', 'sms')
                $("#sfm_sign_in .sfm_form").attr('index', 'sms')
            }
            $("#sfm_sign_in .sfm_form")[0].reset()
        })
    }

    $(document).ready(function () {
        //实例化 ClipboardJS对象;
        var copyBtn = new ClipboardJS('.referral-copy');
        //设置复制功能
        $("#copy").on("click", function () {
            copyBtn.on("success", function (e) {
                // 复制成功
                $("body").append('<div class="sfm-flash"><div class="sfm-flash-msg"><span class="notice">复制成功!</span></div></div>')
                timer = setTimeout(function () {
                    $('.sfm-flash').remove()
                }, 2000)
                e.clearSelection();
            });
            copyBtn.on("error", function (e) {
                //复制失败；
                $("body").append('<div class="sfm-flash"><div class="sfm-flash-msg"><span class="notice">复制失败，请手动复制！</span></div></div>')
                timer = setTimeout(function () {
                    $('.sfm-flash').remove()
                }, 2000)
            });
        });
        //评分
        var starRating = 0;
        window.initScore = function () {
            starRating = Number($("#score").val()) || 0;
            if (starRating != 0) {
                var numb = starRating / 2;
                if (numb == 5) {
                    $('.photo span').find('.high').css('left', 0);
                } else {
                    $('.photo span').eq(numb).prevAll().find('.high').css('left', 0);
                }
            }
        }
        initScore()
        //鼠标移入
        $(document).on("mouseenter", ".photo span", function () {
            var index = $(this).index() + 1;
            $('#one').prevAll().find('.high').css('left', 0);
            $(this).prevAll().find('.high').css('left', 0);
            $(this).find('.high').css('left', 0);
            $(this).nextAll().find('.high').css('left', '-34px');
            $('.starNum').html((index * 2).toFixed(1) + '分');
        });
        //鼠标离开
        $(document).on("mouseleave", ".photo", function () {
            $(this).find('.high').css('left', '-34px');
            var count = starRating / 2;
            if (count == 5) {
                $(this).find('span .high').css('left', 0);
            } else {
                $(this).find('span').eq(count).prevAll().find('.high').css('left', 0);
            }
            $('.starNum').html(starRating.toFixed(1) + '分')
        }), //点击
            $(document).on("click", ".photo span", function () {
                var index = $(this).index() + 1;
                $(this).prevAll().find('.high').css('left', 0)
                $(this).find('.high').css('left', 0);
                starRating = index * 2;
                $('.starNum').html(starRating.toFixed(1) + '分');
                $("#score").val(starRating.toFixed(1))
            });
    });

    //留言提交

    $(document).on('change', '#upload', function (event) {
        var that = $(this)
        if (event.preventDefault) {
            event.preventDefault();
        } else {
            window.event.returnValue == false;
        }
        clearTimeout(timer)
        var file = $('#upload')[0].files[0];
        var date = new Date()
        var url = date.getFullYear() + "-" + (date.getMonth() + 1) + '-' + date.getDate() + '/' + date.getTime() + '-' + file.name
        client.multipartUpload(url, file).then(function (result) {
            console.log(result);
            $('.img').attr('src', result.res.requestUrls[0].split('?')[0]).css('display', 'inline-block')
            $('.imgUrl').val(result.res.requestUrls[0].split('?')[0])
            $('.imgName').val(file.name)
        }).catch(function (err) {
            console.log(err);
        });
    });

    $(document).on('click', '#sfm_message input[type="submit"]', function (event) {
        var that = $(this)
        if (event.preventDefault) {
            event.preventDefault();
        } else {
            window.event.returnValue == false;
        }
        clearTimeout(timer)
        let userName = $("#message_user_name").val().trim()
        let phoneNumber = $('#message_phone_number').val().trim()
        let email = $('#message_email').val().trim()
        let content = $("#message_content").val().trim()
        let imgUrl = $('.imgUrl').val()
        let imgName = $('.imgName').val()
        if (!email) {
            $("body").append('<div class="sfm-flash"><div class="sfm-flash-msg"><span class="notice">邮箱不能为空</span></div></div>')
            timer = setTimeout(function () {
                $('.sfm-flash').remove()
            }, 2000)
            return
        }
        if (!isEmailValid(email)) {
            $("body").append('<div class="sfm-flash"><div class="sfm-flash-msg"><span class="notice">邮箱格式不正确</span></div></div>')
            timer = setTimeout(function () {
                $('.sfm-flash').remove()
            }, 2000)
            return
        }
        if (!content) {
            $("body").append('<div class="sfm-flash"><div class="sfm-flash-msg"><span class="notice">用户留言不能为空</span></div></div>')
            timer = setTimeout(function () {
                $('.sfm-flash').remove()
            }, 2000)
            return
        }
        if (phoneNumber && !isPhoneValid(phoneNumber.trim())) {
            $("body").append('<div class="sfm-flash"><div class="sfm-flash-msg"><span class="notice">手机格式不正确</span></div></div>')
            timer = setTimeout(function () {
                $('.sfm-flash').remove()
            }, 2000)
            return
        }
        that.find("input[type='submit']").prop('disabled', true)
        $.ajax({
            type: "post", url: '/message/post_message', data: $.param({
                'user_name': userName,
                'email': email,
                'phone_number': phoneNumber,
                'content': content,
                'img_url': imgUrl,
                'img_name': imgName
            }), dataType: "json", success: function (res) {
                that.find("input[type='submit']").prop('disabled', false)
                if (res.code == "0") {
                    location.href = "/"
                }
            }
        });
    });

    $(".handleZan").click(function () {
        if ($(".user_id").length > 0) {
            var that = $(this), flag = $('.flag').val()
            if (flag == 'true') {
                that.find(".img1").css("display", "none")
                that.find("strong").css("display", "none")
                that.find(".img2").css({
                    "display": "inline-block",
                })
                $('.flag').val('false')
                $(".id").val('')
                $(".all_zan").text(Number($(".all_zan").text()) + 1)
            } else {
                that.find(".img1").css("display", "inline-block")
                that.find("strong").css("display", "inline-block")
                that.find(".img2").css({
                    "display": "none",
                })
                $('.flag').val('true')
                $(".id").val('888')
                $(".all_zan").text(Number($(".all_zan").text()) - 1)
            }
            $.ajax({
                type: "post",
                url: "/api/post_zan",
                dataType: "json",
                data: $('.zan').serialize(),
                success: function (res) {
                }
            });
        } else {
            $("#sfm_zan, .sfm_fade").css("display", "block")
        }
    })

    $(document).on('click', '.sfm_ok', function (event) {
        clearTimeout(timer)
        if ($(this).parents("form").children("textarea").val() || Number($(this).parents("form").children("#score").val()) != 0) {
            $.ajax({
                type: "post",
                url: "/api/post_comment",
                dataType: "json",
                data: $('.comment').serialize(),
                success: function (res) {
                    if (res.code == 0) {
                        location.reload()
                    }
                }
            });
        } else {
            $("body").append('<div class="sfm-flash"><div class="sfm-flash-msg"><span class="notice">没有评论内容不能提交，要想关闭评论弹框，请点击取消按钮！</span></div></div>')
            timer = setTimeout(function () {
                $('.sfm-flash').remove()
            }, 2000)
        }

    });

    //地图初始化
    if ($("#contact").length > 0) {
        map1(Number(120.89747), Number(31.99886), 'cn')
        $('.amap-logo, .amap-copyright').remove()
    }

    function map1(j, w, language) {
        var map = new AMap.Map("contact", {
            resizeEnable: true, center: [j, w], zoom: 12, lang: language
        });

        addMarker();

        //添加marker标记
        function addMarker() {
            map.clearMap();
            var marker = new AMap.Marker({
                map: map, position: [j, w]
            });
        }

        // 信息窗体的内容
        var content = []
        content = ["<span style='color: red;font-size: 16px;padding-bottom: 15px;'>南通塔扬信息科技有限公司</span>", "电话: 13912273410", "地址: 南通创新中心D座9楼"];

        // 创建 infoWindow 实例
        var infoWindow = new AMap.InfoWindow({
            content: content.join("<br>"),  //传入 dom 对象，或者 html 字符串
            offset: new AMap.Pixel(10, -30)
        });

        var position = new AMap.LngLat(120.89747, 31.99886);

        // 打开信息窗体
        infoWindow.open(map, position);
    }

    //send email
    // if email is valid, enable button of send_email_captcha
    $(document).on('keyup', '.users-email-setting-form #email', function (event) {
        // count down
        if (window.emailCaptchaTimer !== undefined) {
            return
        }

        let email = $(this).val();
    });

    // send email captcha for user email setting
    $(document).on('click', '.users-email-setting-form #send_email_captcha', function (event) {
        let email = $(".users-email-setting-form").find("#email").val();
        if (!email) {
            clearTimeout(timer)
            $("body").append('<div class="sfm-flash"><div class="sfm-flash-msg"><span class="notice">邮箱不能为空!</span></div></div>')
            timer = setTimeout(function () {
                $('.sfm-flash').remove()
            }, 2000)
        } else if (!isEmailValid(email)) {
            clearTimeout(timer)
            $("body").append('<div class="sfm-flash"><div class="sfm-flash-msg"><span class="notice">邮箱输入不正确!</span></div></div>')
            timer = setTimeout(function () {
                $('.sfm-flash').remove()
            }, 2000)
        } else {
            emailCaptchaCountDown(this);
            $.post("/users/0/send_email_captcha.json", {
                email: email, authenticity_token: $("input[name='authenticity_token']").val()
            }).done(res => {
                if (res.code == '00001') {
                    resetPhoneCaptchaBtn(this)
                    $("body").append('<div class="sfm-flash"><div class="sfm-flash-msg"><span class="notice">' + res.message + '</span></div></div>')
                    timer = setTimeout(function () {
                        $('.sfm-flash').remove()
                    }, 2000)
                }
            }).fail(err => {
                console.log(err)
            });
        }
    });

    // send sms code for user phone setting
    $(document).on('click', '.users-phone-setting-form #send_phone_captcha, .sfm_form.new_user #send_phone_captcha', function (event) {
        let phone = $("#sfm_sign_in").find("#user_phone_number").val() || $("#sfm_bind_phone").find("#phone").val() || $("#sfm_sign_in").find("#phone").val()
        if (!phone) {
            clearTimeout(timer)
            $("body").append('<div class="sfm-flash"><div class="sfm-flash-msg"><span class="notice">手机号不能为空!</span></div></div>')
            timer = setTimeout(function () {
                $('.sfm-flash').remove()
            }, 2000)
        } else if (!isPhoneValid(phone)) {
            clearTimeout(timer)
            $("body").append('<div class="sfm-flash"><div class="sfm-flash-msg"><span class="notice">手机号输入不正确!</span></div></div>')
            timer = setTimeout(function () {
                $('.sfm-flash').remove()
            }, 2000)
        } else {
            emailCaptchaCountDown(this);
            let event = $(this).attr('index') == "send_code" ? 'sign_in' : ''
            $.post("/sms/send_verify_code", {
                phone_number: phone, authenticity_token: $("input[name='authenticity_token']").val(), event: event
            }).done(res => {
                if (!res.code) {
                    window.location.href = '/'
                }
                if (res.code == '00001') {
                    resetPhoneCaptchaBtn(this)
                    $("body").append('<div class="sfm-flash"><div class="sfm-flash-msg"><span class="notice">' + res.message + '</span></div></div>')
                    timer = setTimeout(function () {
                        $('.sfm-flash').remove()
                    }, 2000)
                }
            }).fail(err => {
                console.log(err)
            });
        }
    });

    //通过邮件地址链接修改密码
    $(document).on('submit', '.resetPasswordForm', function (e) {
        if (e.preventDefault) {
            e.preventDefault();
        } else {
            window.event.returnValue == false;
        }
        clearTimeout(timer)
        var that = $(this)
        that.find("input[type='submit']").prop('disabled', true)
        $.ajax({
            type: "post",
            url: "/users/password",
            contentType: "application/x-www-form-urlencoded",
            data: $(this).serialize(),
            success: function (res) {
                that.find("input[type='submit']").prop('disabled', false)
                if (res.code == 0) {
                    window.location.href = "/"
                } else {
                    $("body").append('<div class="sfm-flash"><div class="sfm-flash-msg"><span class="notice">' + res.message + '</span></div></div>')
                    timer = setTimeout(function () {
                        $('.sfm-flash').remove()
                    }, 2000)
                }
            }
        });
    })

    //登录忘记密码时，邮箱提交来修改密码
    $(document).on('click', '.changePasswordForm button, .changePasswordForm input[type="submit"]', function (e) {
        if (e.preventDefault) {
            e.preventDefault();
        } else {
            window.event.returnValue == false;
        }
        if (!$(this).parents("form").find("#user_email").val()) {
            $("body").append('<div class="sfm-flash"><div class="sfm-flash-msg"><span class="notice">邮箱不能为空!</span></div></div>')
            timer = setTimeout(function () {
                $('.sfm-flash').remove()
            }, 2000)
            return
        }
        if (!isEmailValid($(this).parents("form").find("#user_email").val())) {
            $("body").append('<div class="sfm-flash"><div class="sfm-flash-msg"><span class="notice">邮箱格式不正确!</span></div></div>')
            timer = setTimeout(function () {
                $('.sfm-flash').remove()
            }, 2000)
            return
        }
        var that = $(this)
        that.prop('disabled', true).css('background', '#b7c2c8').text('发送中。。')
        $.ajax({
            type: "post",
            url: "/users/password",
            contentType: "application/x-www-form-urlencoded",
            data: $.param({'authenticity_token': $("meta[name='csrf-token']").attr("content")}) + '&' + $(this).parents("form").serialize(),
            success: function (res) {
                that.prop('disabled', false).css('background', '#249ddc').text('发送验证码')
                if (res.code == "00001") {
                    $("body").append('<div class="sfm-flash"><div class="sfm-flash-msg"><span class="notice">' + res.message + '</span></div></div>')
                } else {
                    window.location.reload()
                }
            }
        });
    });

    //邮箱验证码提交
    $(document).on('click', '.email_submit_btn', function (e) {
        if (e.preventDefault) {
            e.preventDefault();
        } else {
            window.event.returnValue == false;
        }
        clearTimeout(timer)
        if (!$("#captcha").val() || !$("#email").val()) {
            $("body").append('<div class="sfm-flash"><div class="sfm-flash-msg"><span class="notice">邮箱或者邮箱验证码为空！</span></div></div>')
            timer = setTimeout(function () {
                $('.sfm-flash').remove()
            }, 2000)
            return
        }
        $.ajax({
            type: "post",
            url: "/users/" + $("input[name='id']").val() + "/save_email",
            dataType: "json",
            data: $.param({'authenticity_token': $("meta[name='csrf-token']").attr("content")}) + '&' + $(this).parents("form").serialize(),
            success: function (res) {
                if (res.code == "00001") {
                    resetEmailCaptchaBtn()
                    $("body").append('<div class="sfm-flash"><div class="sfm-flash-msg"><span class="notice">' + res.message + '</span></div></div>')
                    timer = setTimeout(function () {
                        $('.sfm-flash').remove()
                    }, 2000)
                } else {
                    window.location.reload()
                }
            }
        });
    });

    //手机验证码提交
    $(document).on('click', '.phone_submit_btn', function (e) {
        if (e.preventDefault) {
            e.preventDefault();
        } else {
            window.event.returnValue == false;
        }
        clearTimeout(timer)
        // $(this).parent('.users-phone-setting-form').find("#phone")
        console.log($(this).parent('.users-phone-setting-form').find("#phone").val())
        if (!$(this).parent('.users-phone-setting-form').find("#phone").val()) {
            $("body").append('<div class="sfm-flash"><div class="sfm-flash-msg"><span class="notice">请输入手机号！</span></div></div>')
            timer = setTimeout(function () {
                $('.sfm-flash').remove()
            }, 2000)
            return
        }

        if (!$(this).parent('.users-phone-setting-form').find("#code").val()) {
            $("body").append('<div class="sfm-flash"><div class="sfm-flash-msg"><span class="notice">请输入验证码！</span></div></div>')
            timer = setTimeout(function () {
                $('.sfm-flash').remove()
            }, 2000)
            return
        }

        let url = '', params = {}
        if ($(this).attr('index') == 'sign_in') {
            url = "/users/sign_in"
            params = {
                'user[phone_number]': $(this).parent('.users-phone-setting-form').find("#phone").val(),
                'user[code]': $(this).parent('.users-phone-setting-form').find("#code").val(),
                'authenticity_token': $("input[name='authenticity_token']").val(),
                "login_path": "sms_login"
            }
        } else {
            url = "/users/" + $("input[name='id']").val() + "/save_phone_number"
            params = {
                'phone_number': $(this).parent('.users-phone-setting-form').find("#phone").val(),
                'code': $(this).parent('.users-phone-setting-form').find("#code").val(),
                'authenticity_token': $("input[name='authenticity_token']").val(),
            }
        }

        $.post(url, params).done(res => {
            if (!res.code) {
                window.location.href = '/'
            }
            if (res.code == "00001") {
                resetEmailCaptchaBtn()
                $("body").append('<div class="sfm-flash"><div class="sfm-flash-msg"><span class="notice">' + res.message + '</span></div></div>')
                timer = setTimeout(function () {
                    $('.sfm-flash').remove()
                }, 2000)
            } else {
                if (window.screen.width <= 750) {
                    window.location.href = '/apps?type=recommend'
                } else {
                    window.location.reload()
                }
            }
        }).fail(err => {
            console.log(err)
        });
    });

    //购买数量事件
    let reg = /^(([1-9][0-9]{0,3})|10000)$/
    let is_choosed_payment_method = false, is_choosed_agrement = false
    let point_num = 20
    $('.sfm_buy_num li').not('.last').click(function () {
        point_num = Number($(this).attr("num")) * 10
        $('.sfm_buy_num li').removeClass('active')
        $(this).addClass('active')
        $('.money span').text($(this).attr("num"))
        $('#pay_amount').val($(this).attr("num"))
        $('.error').css('display', 'none')
        $('.sfm_buy_num li.last input').val('')
        if (is_choosed_agrement && is_choosed_payment_method && reg.test(point_num)) {
            $(".sfm_buy .content input[type='submit']").attr('disabled', false).css('background', '#409EFF')
        }
    })

    $('.payment_method img').click(function () {
        is_choosed_payment_method = true
        let str = $('.sfm_buy_num li.last input').val() || point_num
        $('.payment_method img').removeClass('active')
        $('#pay_way').val($(this).attr('pay'))
        $(this).addClass('active')
        if (is_choosed_agrement && reg.test(Number(str))) {
            $(".sfm_buy .content input[type='submit']").attr('disabled', false).css('background', '#409EFF')
        }
    })


    $(".agreement input").click(function () {
        is_choosed_agrement = $(".agreement input[type='checkbox']").is(':checked')
        let str = $('.sfm_buy_num li.last input').val() || point_num
        if (is_choosed_agrement && is_choosed_payment_method && reg.test(Number(str))) {
            $(".sfm_buy .content input[type='submit']").attr('disabled', false).css('background', '#409EFF')
        } else {
            $(".sfm_buy .content input[type='submit']").attr('disabled', true).css('background', '#c4c4c4')
        }
    })

    $('.sfm_buy_num li.last').bind('input propertychange', function () {
        let str = $('.sfm_buy_num li.last input').val()
        $('.sfm_buy_num li').removeClass('active')
        if (reg.test(Number(str))) {
            $('.money span').text((Number($('.sfm_buy_num li.last input').val()) / 10).toFixed(2))
            $('#pay_amount').val((Number($('.sfm_buy_num li.last input').val()) / 10).toFixed(2))
            $('.error').css('display', 'none')
            if (is_choosed_agrement && is_choosed_payment_method && reg.test(Number(str))) {
                $(".sfm_buy .content input[type='submit']").attr('disabled', false).css('background', '#409EFF')
            } else {
                $(".sfm_buy .content input[type='submit']").attr('disabled', true).css('background', '#c4c4c4')
            }
        } else {
            $('.error').css('display', 'block')
            $(".sfm_buy .content input[type='submit']").attr('disabled', true).css('background', '#c4c4c4')
        }
    })


    $("footer > span").append(unescape("%3Cspan id='cnzz_stat_icon_1279393464'%3E%3C/span%3E%3Cscript src='https://s4.cnzz.com/z_stat.php%3Fid%3D1279393464%26show%3Dpic1' type='text/javascript'%3E%3C/script%3E"))

    $(document).on('click', '.order-set', function (e) {
        if ($(this).attr("class").includes("selected")) {
            $(this).attr("class", "order-set");
        } else {
            $(".order-set").attr("class", "order-set");
            $(this).attr("class", "order-set selected");
            $(".vip-payment-method").find("span.fee").html($(this).attr("fee"));
            $("#pay_period").val($(this).attr("period"));
            $("#vip_set_id").val($(this).attr("vip_set_id"));
        }
    });

    $(document).on('click', '.vip-subscribe .vip-payment-method img', function (e) {
        $('.vip-payment-method img').removeClass('active')
        $(this).attr("class", "active");
        $(".pay-btn").attr('disabled', false).css('background', '#409EFF')
        $("#pay_method").val($(this).attr("pay"));
    });

    $(document).on('click', '.sfm-back-top', function (e) {
        document.body.scrollTo({
            top: 0,
            behavior: 'smooth'
        })
    });

    $(document).on('click', '.sfm-back-top-mobile', function (e) {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        })
    });

    $('body').scroll(function () {//开始监听滚动条 绑定在body上是因为有overflow 与window.scroll冲突
        if ($('body').scrollTop() > 0) {
            $("#back-top").fadeIn('fast')
        } else {
            $("#back-top").fadeOut('fast')
        }
    })
})

//60s倒计时
var countdown = 60

function emailCaptchaCountDown(obj) {
    if (countdown == 0) {
        $(obj).attr("disabled", false)
        $(obj).text("获取验证码")
        countdown = 60;
        return;
    } else {
        $(obj).attr("disabled", true)
        $(obj).text(countdown + "s")
        countdown--;
    }
    window.emailCaptchaTimer = setTimeout(function () {
        emailCaptchaCountDown(obj)
    }, 1000)
}

function resetEmailCaptchaBtn() {
    clearTimeout(window.emailCaptchaTimer);
    countdown = 60
    $("#send_email_captcha").text("发送验证码").removeAttr("disabled")
}

function resetPhoneCaptchaBtn(obj) {
    clearTimeout(window.emailCaptchaTimer);
    countdown = 60
    $(obj).text("发送验证码").removeAttr("disabled")
}


// validate email address
function isEmailValid(email) {
    return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,6}$/i.test(email)
}

// validate email address
function isPhoneValid(phone) {
    return /^1([358][0-9]|4[579]|66|7[0135678]|9[89])[0-9]{8}$/.test(phone)
}

window.showModal = function (data, id) {
    $(".sfm_modal").css("display", "none")
    $(".sfm_fade").css("display", "block")
    $("#general-modal-area").html(data)
    $("#general-modal-area").find(id).css("display", "block")
}

window.closeModal = function () {
    $('.sfm_modal, .sfm_fade').css('display', 'none');
}

window.alertNotice = function (msg) {
    alertMessage(msg, 'notice', 2000);
}

window.alertError = function (msg) {
    alertMessage(msg, 'error', 4000);
}

window.alertMessage = function (msg, type, duration) {
    const container = document.createElement('div');
    container.className = "sfm-flash";
    container.innerHTML = `<div class="sfm-flash-msg"><span class="${type}">${msg}</span></div>`
    $("body").append(container)
    return setTimeout(function () {
        container.remove();
    }, duration)
}