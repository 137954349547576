$(() => {
  // 文档搜索自动增加已选中的条件 check-group-item-checked class
  $('.sfm-doc-search-conditions-form input.search-check-box[checked="checked"]')
    .parents(".check-group-item")
    .addClass("check-group-item-checked");

  // 文档搜索条件点击
  $(document).on("change", ".sfm-doc-search-conditions-form .search-check-box", function () {
    // 如果是单选的情况, 取消其他选中
    if ($(this).data("single")) {
      $(this).parents(".check-group").find(".check-group-item-checked").removeClass("check-group-item-checked");
      $(this).parents(".check-group").find('input[type="checkbox"]').not(this).prop("checked", false);
    }

    // 选中加 class
    if ($(this).prop("checked")) {
      $(this).parents(".check-group-item").addClass("check-group-item-checked");
    } else {
      $(this).parents(".check-group-item").removeClass("check-group-item-checked");
    }

    // 选中一级分类 清空二、三级分类
    // 选中二级分类 清空三级分类
    if ($(this).data("category") === 'first') {
      $(this).parents(".sfm-doc-search-conditions-form").find('.search-check-box[data-category="second"]').prop("checked", false);
      $(this).parents(".sfm-doc-search-conditions-form").find('.search-check-box[data-category="third"]').prop("checked", false);
    } else if ($(this).data("category") === 'second') {
      $(this).parents(".sfm-doc-search-conditions-form").find('.search-check-box[data-category="third"]').prop("checked", false);
    }

    // 提交表单
    $(this).parents("form").submit();
  })

  // 文档搜索 排序
  $(document).on("click", ".sfm-documents-search-result .sort-container .sort", function () {
    const filed = $(this).data("field");
    const nextSortOrder = $(this).data("next-sort-order");
    const form = $(this).parents(".sfm-documents-search-result").find(".sfm-doc-search-conditions-form");

    form.find("#sort_field").val(filed);
    form.find("#sort_order").val(nextSortOrder);

    // 提交表单
    form.submit();
  })
})