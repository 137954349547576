class DocReader {
  constructor(el) {
    this.el = el;
    this.docType = this.el.dataset.docType;
    this.isMobile = this.el.dataset.isMobile === 'true';

    this.pagesEL = el.querySelector(".pages");
    if (!this.pagesEL) {
      throw new Error(`undefined pagesEL`);
    }

    this.foldPageEl = el.querySelector(".fold-page");
    if (!this.foldPageEl) {
      throw new Error(`undefined foldPageEl`);
    }

    this.loadingEl = el.querySelector(".loading");
    if (!this.loadingEl) {
      throw new Error(`undefined loadingEl`);
    }

    // use hash id
    this.documentId = el.dataset.id;
    el.removeAttribute("data-id");

    this.drawing = false;
    this.pagesLoaded = 0; // 已经加载的页数
    this.unread = undefined;

    // 每一页宽高
    this.pageWidth = 945;
    this.pageHeight = this.isPPT() ? 725 : 1336.45;
    this.pagePaddingLeft = 30;
    this.pagePaddingTop = 50;
    this.pageTextLineHeight = 30;
    // canvas 缩放比例
    this.canvaScaleRatio = 1;
    this.textFontSize = '14px';
    this.textFontFamily = '-apple-system, BlinkMacSystemFont, Helvetica Neue, PingFang SC, Microsoft YaHei, Source Han Sans SC, Noto Sans CJK SC, WenQuanYi Micro Hei, sans-serif';
    this.setMobilePageStyle()
  }

  // 设置移动端页面样式(直接缩放)
  setMobilePageStyle() {
    if (!this.isMobile) return

    const pageScaleRatio = this.pagesEL.clientWidth / this.pageWidth;
    this.pageWidth *= pageScaleRatio;
    this.pageHeight *= pageScaleRatio;
    this.pagePaddingLeft *= pageScaleRatio;
    this.pagePaddingTop *= pageScaleRatio;
    this.pageTextLineHeight *= pageScaleRatio;
    this.textFontSize = `${14 * pageScaleRatio}px`;
  }

  canvasTextFont() {
    return `${this.textFontSize} ${this.textFontFamily}`
  }

  nextPage() {
    return this.pagesLoaded + 1;
  }

  isPPT() {
    return this.docType === 'ppt';
  }

  // 编码
  encodeData(data) {
    return btoa(data)
  }

  // 解码
  decodeData(data) {
    return decodeURIComponent(atob(data))
  }

  setDrawing(val) {
    this.drawing = val;
    if (val) {
      this.foldPageEl.querySelector(".read-all").textContent = "加载中...";
      this.loadingEl.style.display = "block";
    } else {
      this.foldPageEl.querySelector(".read-all").textContent = "继续阅读";
      this.loadingEl.style.display = "none";
    }
  }

  // 提取图片 url 并进行绘制预览页
  getDocumentPages(limit) {
    if (this.unread && this.unread === 0) {
      return;
    }

    if (this.drawing) {
      return;
    }
    this.setDrawing(true)

    const nextPage = this.nextPage()
    const self = this;
    const data = {
      d: this.encodeData(JSON.stringify({
        id: this.documentId,
        from: nextPage,
        to: nextPage + limit - 1
      }))
    }
    $.ajax({
      url: `/doc/documents/pages`,
      type: "post",
      dataType: "json",
      beforeSend: function (xhr) {
        xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
      },
      data: data,
      success: function (res) {
        const realRes = JSON.parse(self.decodeData(res.d));
        if (realRes.code === 0) {
          self.drawPagesDataToCanvas(realRes.result).finally(() => {
            self.setDrawing(false);
          })
        } else {
          self.setDrawing(false);
        }

        if (realRes.message) {
          alertError(realRes.message);
        }
      },
      error: function (err) {
        console.log(err)
        self.setDrawing(false);
        alertError('加载文档失败，请联系平台');
      }
    });
  }

  // 绘制页面数据到 canvas
  async drawPagesDataToCanvas(data) {
    const {pages, unread} = data;
    this.unread = unread;

    for (const pageData of pages) {
      await this.drawPageDataToCanvas(pageData);
    }

    // 未读数量设置
    if (this.unread > 0) {
      this.foldPageEl.style.display = "block";
      this.foldPageEl.style.marginTop = `${-this.pageHeight * 0.4}px`;
      this.foldPageEl.querySelector(".unread-count").textContent = `${this.unread}`;
    } else {
      this.foldPageEl.style.display = "none";
      this.foldPageEl.querySelector(".unread-count").textContent = '0';
    }
  }

  // 解析单页 json 数据绘制到 canvas 中
  async drawPageDataToCanvas(data) {
    const {type, page} = data;
    switch (type) {
      case 'image':
        await this.canvasDrawImage(this.pagesEL, data.image, page);
        break;
      case 'txt':
        await this.canvasDrawText(this.pagesEL, data.lines, page);
        break;
      default:
        break;
    }
    this.pagesLoaded = page;
  }

  // 使用 canvas 绘制图片
  canvasDrawImage(parentEl, imgSrc, pageNumber) {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.crossOrigin = 'anonymous';
      img.onload = () => {
        // 创建 canvas 元素
        const {canvasContainer, canvas, err} = this.createCanvasElement(pageNumber);
        if (err) {
          reject(err)
          return
        }

        const ctx = canvas.getContext('2d');
        ctx.globalCompositeOperation = 'destination-over';
        this.setCanvasScale(canvas, ctx)
        ctx.drawImage(img, ...this.aspectFit(img.width, img.height, this.pageWidth, this.pageHeight));
        parentEl.appendChild(canvasContainer);
        resolve(img)
      };
      img.src = imgSrc;
    })
  }

  // 使用 canvas 绘制文本
  canvasDrawText(parentEl, lines, pageNumber) {
    return new Promise((resolve, reject) => {
      // 创建 canvas 元素
      const {canvasContainer, canvas, err} = this.createCanvasElement(pageNumber);
      if (err) {
        reject(err)
        return
      }

      const ctx = canvas.getContext('2d');
      ctx.globalCompositeOperation = 'destination-over';
      this.setCanvasScale(canvas, ctx)
      ctx.font = this.canvasTextFont();

      let index = 0
      lines.forEach((line) => {
        ctx.fillText(line,
          this.pagePaddingLeft,
          index * this.pageTextLineHeight + this.pagePaddingTop,
          this.canvaScaleRatio * this.pageWidth - 2 * this.pagePaddingLeft);
        index += 1
      })

      parentEl.appendChild(canvasContainer);
      resolve()
    })
  }

  // 创建 canvas 元素
  createCanvasElement(pageNumber) {
    let err;
    const canvasContainerId = `page-${pageNumber}`
    if (document.getElementById(canvasContainerId)) {
      err = new Error("element already exists")
      return {err}
    }

    const canvasContainer = document.createElement('div');
    canvasContainer.id = canvasContainerId;
    canvasContainer.className = "page-no";
    const canvas = document.createElement('canvas');
    canvasContainer.appendChild(canvas);
    return {canvasContainer, canvas, err};
  }

  // 设置 canvas 缩放样式
  setCanvasScale(canvasEL, canvasCtx) {
    // 设置缩放, 避免在显示器缩放时产生偏差
    let devicePixelRatio = window.devicePixelRatio || 1;
    let backingStoreRatio = canvasCtx.webkitBackingStorePixelRatio || canvasCtx.mozBackingStorePixelRatio || canvasCtx.msBackingStorePixelRatio || canvasCtx.oBackingStorePixelRatio || canvasCtx.backingStorePixelRatio || 1;
    // 计算比例
    this.canvaScaleRatio = devicePixelRatio / backingStoreRatio;
    // 原始大小
    let canvasWidth = this.pageWidth;
    let canvasHeight = this.pageHeight;
    // 將"画布宽高"依照比例放大
    canvasEL.width = canvasWidth * this.canvaScaleRatio;
    canvasEL.height = canvasHeight * this.canvaScaleRatio;
    // 设置"画布样式宽高"
    canvasEL.style.width = `${canvasWidth}px`;
    canvasEL.style.height = `${canvasHeight}px`;
    // 將內容依照比例放大
    canvasCtx.scale(this.canvaScaleRatio, this.canvaScaleRatio);
  }

  // 保持纵横比, 计算位置
  aspectFit(contentWidth, contentHeight, canvasWidth, canvasHeight) {
    const contentRate = contentWidth / contentHeight
    const canvasRate = canvasWidth / canvasHeight
    let [dx, dy, dw, dh] = []
    if (contentRate >= canvasRate) {
      dw = canvasWidth
      dh = canvasWidth / contentRate
    } else {
      dh = canvasHeight
      dw = canvasHeight * contentRate
    }
    dx = (canvasWidth - dw) / 2
    dy = (canvasHeight - dh) / 2
    return [dx, dy, dw, dh]
  }
}

$(() => {
  const readerEl = $(".sfm-document-reader")[0];
  let reader;
  if (readerEl) {
    reader = new DocReader(readerEl)
    // 绘制预览页
    reader.getDocumentPages(2);
  }

  // 是否在可视范围中
  function isInViewport(el) {
    const viewPortHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight
    const top = el.getBoundingClientRect() && el.getBoundingClientRect().top
    return top <= viewPortHeight
  }

  // 设置工具栏位置
  function setDocToolbarPosition() {
    const docToolbarViewport = document.getElementById("doc-toolbar-viewport");
    if (!docToolbarViewport) return

    if (isInViewport(docToolbarViewport)) {
      $(".doc-toolbar-wrapper").removeClass("fade-in").addClass("static")
    } else {
      $(".doc-toolbar-wrapper").removeClass("static").addClass("fade-in")
    }
  }

  // 滚动时设置工具栏位置
  // TODO throttle
  if ($("#doc-toolbar-viewport").length > 0) {
    window.addEventListener("scroll", setDocToolbarPosition, true);
  }

  // 阅读更多内容
  $("body").on("click", ".sfm-document-reader .fold-page", function () {
    reader.getDocumentPages(10);
  })
})